import React, { useRef, useEffect } from 'react';

const InfoModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={modalRef} className="bg-gray-800 p-6 rounded-lg shadow-lg text-white max-w-2xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">How to Use the Prompter</h2>
        <div className="space-y-4">
          <section>
            <h3 className="text-xl font-semibold mb-2">Controls</h3>
            <ul className="list-disc list-inside">
              <li><strong>Font Size:</strong> Adjust using the + and - buttons or enter a value directly.</li>
              <li><strong>Text Width:</strong> Change the width of the text area using the slider.</li>
              <li><strong>Speed:</strong> Control the auto-scroll speed with the slider.</li>
              <li><strong>Start/Stop:</strong> Toggle auto-scrolling.</li>
            </ul>
          </section>
          <section>
            <h3 className="text-xl font-semibold mb-2">Keyboard Shortcuts</h3>
            <ul className="list-disc list-inside">
              <li><strong>Space Bar:</strong> Start or stop auto-scrolling.</li>
              <li><strong>Up Arrow:</strong> Increase scroll speed.</li>
              <li><strong>Down Arrow:</strong> Decrease scroll speed.</li>
            </ul>
          </section>
          <section>
            <h3 className="text-xl font-semibold mb-2">Screen Width Matching</h3>
            <p>The prompter adapts to different screen sizes to ensure consistent viewing for both the controller and the presenter:</p>
            <ul className="list-disc list-inside">
              <li>The controller sets the desired text width using the slider.</li>
              <li>If the presenter's screen is narrower, the text width automatically adjusts to fit their screen.</li>
              <li>The controller's view will match the presenter's, showing the actual width in use.</li>
              <li>When the presenter's screen width increases, the text expands up to the controller's set width.</li>
              <li>The Text Width indicator shows both the set width and the actual width in use (if different).</li>
            </ul>
          </section>
          <section>
            <h3 className="text-xl font-semibold mb-2">Additional Features</h3>
            <ul className="list-disc list-inside">
              <li><strong>Manual Scroll:</strong> Use your mouse wheel or trackpad to manually adjust the position.</li>
              <li><strong>Full Screen:</strong> Enter full-screen mode for distraction-free viewing.</li>
              <li><strong>Share Link:</strong> Generate a link for a view-only version of the prompter.</li>
              <li><strong>Edit:</strong> Return to the script input page to make changes.</li>
            </ul>
          </section>
          <section className="pt-4 border-t border-gray-600">
            <p className="text-sm">
              If you have any issues, try refreshing the page and re-send the presenter link. If that fails, please reach out to{' '}
              <a 
                href="mailto:jeremy.clark@nextslide.io" 
                className="text-blue-400 hover:underline"
              >
                jeremy.clark@nextslide.io
              </a>
               and let us know the issue you are experiencing. 
            </p>
          </section>
        </div>
        <button
          onClick={onClose}
          className="mt-6 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default InfoModal;
