import React, { useRef, useEffect } from 'react';

const LinkCopiedModal = ({ isOpen, onClose, error, link }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const copyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      alert('Link copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
      alert('Failed to copy link. Please copy it manually.');
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={modalRef} className="bg-gray-800 p-6 rounded-lg shadow-lg text-white max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">
          {error ? "Copy Failed" : "Link Copied"}
        </h2>
        {error ? (
          <p className="mb-4 text-red-400">{error}</p>
        ) : (
          <p className="mb-4">The shareable link has been copied to your clipboard.</p>
        )}
        <p className="mb-2">Shareable link:</p>
        <div className="flex items-center mb-4">
          <input
            type="text"
            value={link}
            readOnly
            className="flex-grow p-2 bg-gray-700 rounded-l text-sm"
            onClick={(e) => e.target.select()}
          />
          <button
            onClick={copyLink}
            className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Copy
          </button>
        </div>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default LinkCopiedModal;