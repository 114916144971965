import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import ScriptInput from './ScriptInput';
import PrompterController from './PrompterController';
import PrompterView from './PrompterView';

const Teleprompter = ({ isController = false }) => {
  const [state, setState] = useState({
    text: '',
    scrollSpeed: 2,
    position: 0,
    fontSize: 72,
    textWidth: 1000,
    isScrolling: false,
    showPrompter: false,
    viewerWidth: null,
    controllerSetWidth: 1000,
    isAllCaps: false,
    isFlippedHorizontally: false,
    isFlippedVertically: false,
  });
  const [socket, setSocket] = useState(null);
  const { id } = useParams();
  const containerRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const maxWidthRef = useRef(1000);

  const shareableLink = `${window.location.origin}/view/${id}`;

  useEffect(() => {
    const socketURL = process.env.REACT_APP_SOCKET_URL || 'https://nextprompt-server.onrender.com';
    console.log('Connecting to socket at:', socketURL);
    const newSocket = io(socketURL, {
      withCredentials: true,
      transports: ['websocket', 'polling'],
      upgrade: true,
      reconnectionAttempts: 5,
      timeout: 10000
    });

    newSocket.on('connect', () => {
      console.log('Connected to server. Socket ID:', newSocket.id);
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection error:', error.message);
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Disconnected from server. Reason:', reason);
    });

    setSocket(newSocket);

    if (id) {
      newSocket.emit('join-room', id);
    }

    return () => newSocket.close();
  }, [id]);

  const emitUpdate = useCallback((updates) => {
    if (socket) {
      console.log('Emitting state update:', updates);
      socket.emit('state-update', updates);
    }
  }, [socket]);

  const handleViewerWidthChange = useCallback((width) => {
    setState(prevState => {
      const newTextWidth = Math.min(width, prevState.controllerSetWidth);
      return { 
        ...prevState, 
        viewerWidth: width, 
        textWidth: newTextWidth 
      };
    });
    emitUpdate({ viewerWidth: width });
  }, [emitUpdate]);

  const handleScrollingChange = useCallback(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }

    if (state.isScrolling) {
      scrollIntervalRef.current = setInterval(() => {
        setState(prevState => {
          const newPosition = prevState.position + prevState.scrollSpeed * 0.5;
          return { ...prevState, position: newPosition };
        });
      }, 16); // 60 FPS
    }
  }, [state.isScrolling, state.scrollSpeed]);

  useEffect(() => {
    handleScrollingChange();
    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [handleScrollingChange]);

  useEffect(() => {
    if (socket) {
      socket.on('state-update', (newState) => {
        console.log('Received state update:', newState);
        setState(prevState => {
          const updatedState = { ...prevState, ...newState };
          if ('textWidth' in newState) {
            updatedState.controllerSetWidth = newState.textWidth;
            updatedState.textWidth = Math.min(newState.textWidth, prevState.viewerWidth || Infinity);
          }
          if ('viewerWidth' in newState && isController) {
            updatedState.textWidth = Math.min(prevState.controllerSetWidth, newState.viewerWidth);
          }
          return updatedState;
        });
      });

      if (!isController) {
        console.log('Requesting full state');
        socket.emit('request-full-state');
      }

      return () => {
        socket.off('state-update');
      };
    }
  }, [socket, isController]);

  const handleStateChange = useCallback((updates) => {
    setState(prevState => {
      const newState = { ...prevState, ...updates };
      if ('textWidth' in updates) {
        newState.controllerSetWidth = updates.textWidth;
        newState.textWidth = Math.min(updates.textWidth, prevState.viewerWidth || Infinity);
      }
      return newState;
    });
    emitUpdate(updates);
  }, [emitUpdate]);

  const handleControllerWidthChange = useCallback((width) => {
    setState(prevState => {
      const newControllerSetWidth = Math.min(width, maxWidthRef.current);
      if (newControllerSetWidth < prevState.controllerSetWidth) {
        const newTextWidth = Math.min(newControllerSetWidth, prevState.viewerWidth || Infinity);
        emitUpdate({ controllerSetWidth: newControllerSetWidth, textWidth: newTextWidth });
        return {
          ...prevState,
          controllerSetWidth: newControllerSetWidth,
          textWidth: newTextWidth
        };
      }
      return prevState;
    });
  }, [emitUpdate]);

  const handleScroll = useCallback((event) => {
    if (isController) {
      const newPosition = Math.max(0, state.position + event.deltaY);
      handleStateChange({ position: newPosition });
    }
  }, [isController, state.position, handleStateChange]);

  const handleKeyDown = useCallback((event) => {
    switch (event.code) {
      case 'Space':
        event.preventDefault();
        handleStateChange({ isScrolling: !state.isScrolling });
        break;
      case 'ArrowUp':
        handleStateChange({ scrollSpeed: Math.min(state.scrollSpeed + 0.1, 5) });
        break;
      case 'ArrowDown':
        handleStateChange({ scrollSpeed: Math.max(state.scrollSpeed - 0.1, 0) });
        break;
      default:
        break;
    }
  }, [state.isScrolling, state.scrollSpeed, handleStateChange]);

  useEffect(() => {
    if (isController && state.showPrompter) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isController, state.showPrompter, handleKeyDown]);

  const handleRestart = useCallback(() => {
    console.log('Restart function called in Teleprompter');
    handleStateChange({ position: 0 });
  }, [handleStateChange]);

  const handleToggleCase = useCallback(() => {
    console.log('Toggle case function called in Teleprompter');
    handleStateChange({ isAllCaps: !state.isAllCaps });
  }, [handleStateChange, state.isAllCaps]);

  const handleToggleFlipHorizontal = useCallback(() => {
    console.log('Toggle flip horizontal function called in Teleprompter');
    handleStateChange({ isFlippedHorizontally: !state.isFlippedHorizontally });
  }, [handleStateChange, state.isFlippedHorizontally]);

  const handleToggleFlipVertical = useCallback(() => {
    console.log('Toggle flip vertical function called in Teleprompter');
    handleStateChange({ isFlippedVertically: !state.isFlippedVertically });
  }, [handleStateChange, state.isFlippedVertically]);

  const getDisplayText = useCallback(() => {
    return state.isAllCaps ? state.text.toUpperCase() : state.text;
  }, [state.isAllCaps, state.text]);

  if (!isController) {
    return (
      <div className="h-screen flex flex-col bg-black text-white overflow-hidden relative">
        <PrompterView 
          text={getDisplayText()}
          position={state.position} 
          fontSize={state.fontSize} 
          textWidth={state.textWidth}
          showFullScreenButton={true}
          isScrolling={state.isScrolling}
          scrollSpeed={state.scrollSpeed}
          onViewerWidthChange={handleViewerWidthChange}
          isFlippedHorizontally={state.isFlippedHorizontally}
          isFlippedVertically={state.isFlippedVertically}
        />
        {!state.isFlippedHorizontally && !state.isFlippedVertically && (
          <div className="absolute bottom-4 right-4 z-10 flex flex-col items-end">
            <a href="https://www.nextslide.io" target="_blank" rel="noopener noreferrer">
              <img src="https://www.nextslide.io/wp-content/uploads/2024/06/NS-blue.png" alt="NextSlide Logo" className="w-72 h-auto" />
            </a>
          </div>
        )}
      </div>
    );
  }  

  if (isController && !state.showPrompter) {
    return (
      <ScriptInput
        text={state.text}
        setText={(newText) => handleStateChange({ text: newText })}
        onSubmit={() => handleStateChange({ showPrompter: true })}
      />
    );
  }

  return (
    <div ref={containerRef} onWheel={handleScroll} className="h-screen flex flex-col relative">
      <PrompterController
        {...state}
        setPosition={(newPosition) => handleStateChange({ position: newPosition })}
        setFontSize={(newFontSize) => handleStateChange({ fontSize: newFontSize })}
        setTextWidth={(newTextWidth) => handleStateChange({ textWidth: newTextWidth, controllerSetWidth: newTextWidth })}
        setScrollSpeed={(newScrollSpeed) => handleStateChange({ scrollSpeed: newScrollSpeed })}
        setIsScrolling={(newIsScrolling) => handleStateChange({ isScrolling: newIsScrolling })}
        shareableLink={shareableLink}
        onBackToEdit={() => handleStateChange({ showPrompter: false })}
        onRestart={handleRestart}
        onToggleCase={handleToggleCase}
        onToggleFlipHorizontal={handleToggleFlipHorizontal}
        onToggleFlipVertical={handleToggleFlipVertical}
        displayText={getDisplayText()}
        onControllerWidthChange={handleControllerWidthChange}
        maxWidth={maxWidthRef.current}
      />
    </div>
  );
};

export default Teleprompter;