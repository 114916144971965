import React, { useState } from 'react';
import { FaPlay, FaPause, FaExpand, FaCopy, FaEdit, FaQuestionCircle, FaUndo, FaFont, FaArrowsAltH, FaArrowsAltV } from 'react-icons/fa';
import LinkCopiedModal from './LinkCopiedModal';
import InfoModal from './InfoModal';

const Toolbar = ({ 
  fontSize, 
  setFontSize, 
  textWidth,
  setTextWidth,
  viewerWidth,
  controllerSetWidth,
  scrollSpeed, 
  setScrollSpeed, 
  isScrolling, 
  setIsScrolling, 
  shareableLink,
  onBackToEdit,
  onToggleFullScreen,
  controllerScreenWidth,
  onRestart,
  isAllCaps,
  onToggleCase,
  isFlippedHorizontally,
  onToggleFlipHorizontal,
  isFlippedVertically,
  onToggleFlipVertical
}) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [copyError, setCopyError] = useState(null);

  const handleFontSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    if (!isNaN(newSize) && newSize > 0) {
      setFontSize(newSize);
    }
  };

  const handleTextWidthChange = (e) => {
    const newWidth = Math.min(parseInt(e.target.value, 10), controllerScreenWidth);
    setTextWidth(newWidth);
  };

  const copyToClipboard = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(shareableLink)
        .then(() => setIsLinkModalOpen(true))
        .catch((err) => {
          console.error('Failed to copy: ', err);
          fallbackCopyTextToClipboard(shareableLink);
        });
    } else {
      fallbackCopyTextToClipboard(shareableLink);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        setIsLinkModalOpen(true);
      } else {
        setCopyError('Unable to copy to clipboard. Please copy the link manually.');
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      setCopyError('Unable to copy to clipboard. Please copy the link manually.');
    }
    document.body.removeChild(textArea);
  };

  const buttonClass = "bg-blue-500 text-white p-2 rounded hover:bg-blue-600 flex items-center justify-center w-10 h-10";
  const activeButtonClass = "bg-green-500 text-white p-2 rounded hover:bg-green-600 flex items-center justify-center w-10 h-10";
  const inputClass = "bg-gray-700 text-white p-1 rounded";

  return (
    <>
      <div className="p-4 bg-gray-800 flex flex-wrap items-center justify-between gap-4">
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex items-center space-x-2">
            <label className="text-sm text-white flex items-center">
              <span className="hidden sm:inline">Font Size:</span>
              <input
                type="number"
                value={fontSize}
                onChange={handleFontSizeChange}
                className={`mx-2 w-16 text-center ${inputClass}`}
              />
            </label>
            <label className="text-sm text-white flex items-center">
              <span className="hidden sm:inline">Width:</span>
              <input
                type="range"
                min="200"
                max={controllerScreenWidth}
                value={controllerSetWidth}
                onChange={handleTextWidthChange}
                className="ml-2 w-24 sm:w-40"
              />
              <span className="ml-2 hidden sm:inline">{controllerSetWidth}px</span>
              {viewerWidth && viewerWidth < controllerSetWidth && (
                <span className="ml-2 text-yellow-500 hidden sm:inline">
                  (Viewer: {viewerWidth}px)
                </span>
              )}
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <label className="text-sm text-white flex items-center">
              <span className="hidden sm:inline">Speed:</span>
              <input
                type="range"
                min="0"
                max="5"
                step="0.1"
                value={scrollSpeed}
                onChange={(e) => setScrollSpeed(Number(e.target.value))}
                className="ml-2 w-24 sm:w-40"
              />
              <span className="ml-2">{scrollSpeed.toFixed(1)}</span>
            </label>
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <button
            onClick={() => setIsScrolling(!isScrolling)}
            className={isScrolling ? activeButtonClass : buttonClass}
            title={isScrolling ? "Pause" : "Play"}
          >
            {isScrolling ? <FaPause /> : <FaPlay />}
          </button>
          <button
            onClick={onRestart}
            className={buttonClass}
            title="Restart"
          >
            <FaUndo />
          </button>
          <button
            onClick={onToggleCase}
            className={isAllCaps ? activeButtonClass : buttonClass}
            title={isAllCaps ? "Switch to normal case" : "Switch to all caps"}
          >
            <FaFont />
          </button>
          <button
            onClick={onToggleFlipHorizontal}
            className={isFlippedHorizontally ? activeButtonClass : buttonClass}
            title={isFlippedHorizontally ? "Disable horizontal flip" : "Enable horizontal flip"}
          >
            <FaArrowsAltH />
          </button>
          <button
            onClick={onToggleFlipVertical}
            className={isFlippedVertically ? activeButtonClass : buttonClass}
            title={isFlippedVertically ? "Disable vertical flip" : "Enable vertical flip"}
          >
            <FaArrowsAltV />
          </button>
          <button
            onClick={() => setIsInfoModalOpen(true)}
            className={`${buttonClass} bg-gray-600 hover:bg-gray-700`}
            title="Info"
          >
            <FaQuestionCircle />
          </button>
          <button
            onClick={onToggleFullScreen}
            className={buttonClass}
            title="Toggle fullscreen"
          >
            <FaExpand />
          </button>
          <button
            onClick={copyToClipboard}
            className={buttonClass}
            title="Copy shareable link"
          >
            <FaCopy />
          </button>
          <button
            onClick={onBackToEdit}
            className={buttonClass}
            title="Back to edit"
          >
            <FaEdit />
          </button>
        </div>
      </div>
      <LinkCopiedModal 
        isOpen={isLinkModalOpen} 
        onClose={() => setIsLinkModalOpen(false)} 
        error={copyError}
        link={shareableLink}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
      />
    </>
  );
};

export default Toolbar;