import React, { useState, useEffect, useCallback } from 'react';
import Toolbar from './Toolbar';
import PrompterView from './PrompterView';

const PrompterController = ({
  text,
  position,
  setPosition,
  fontSize,
  setFontSize,
  textWidth,
  setTextWidth,
  controllerSetWidth,
  scrollSpeed,
  setScrollSpeed,
  isScrolling,
  setIsScrolling,
  viewerWidth,
  shareableLink,
  onBackToEdit,
  onRestart,
  isAllCaps,
  onToggleCase,
  isFlippedHorizontally,
  onToggleFlipHorizontal,
  isFlippedVertically,
  onToggleFlipVertical,
  displayText,
  onControllerWidthChange,
  maxWidth
}) => {
  const [controllerScreenWidth, setControllerScreenWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    const newWidth = window.innerWidth;
    setControllerScreenWidth(newWidth);
    onControllerWidthChange(newWidth);
  }, [onControllerWidthChange]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  return (
    <div className="h-screen flex flex-col bg-black text-white overflow-hidden relative">
      <div className="flex-shrink-0">
        <Toolbar
          fontSize={fontSize}
          setFontSize={setFontSize}
          textWidth={textWidth}
          setTextWidth={setTextWidth}
          controllerSetWidth={controllerSetWidth}
          viewerWidth={viewerWidth}
          scrollSpeed={scrollSpeed}
          setScrollSpeed={setScrollSpeed}
          isScrolling={isScrolling}
          setIsScrolling={setIsScrolling}
          shareableLink={shareableLink}
          onBackToEdit={onBackToEdit}
          onToggleFullScreen={toggleFullScreen}
          controllerScreenWidth={controllerScreenWidth}
          onRestart={onRestart}
          isAllCaps={isAllCaps}
          onToggleCase={onToggleCase}
          isFlippedHorizontally={isFlippedHorizontally}
          onToggleFlipHorizontal={onToggleFlipHorizontal}
          isFlippedVertically={isFlippedVertically}
          onToggleFlipVertical={onToggleFlipVertical}
          maxWidth={maxWidth}
        />
      </div>
      <div className="flex-grow overflow-hidden relative">
        <PrompterView 
          text={displayText}
          position={position}
          fontSize={fontSize} 
          textWidth={textWidth}
          showFullScreenButton={false}
          isScrolling={isScrolling}
          scrollSpeed={scrollSpeed}
        />
        <div className="absolute bottom-4 right-4 z-10 flex flex-col items-end">
          <a href="https://www.nextslide.io" target="_blank" rel="noopener noreferrer">
            <img src="https://www.nextslide.io/wp-content/uploads/2024/06/NS-blue.png" alt="NextSlide Logo" className="w-72 h-auto" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PrompterController;