import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaExpand, FaCompress } from 'react-icons/fa';

const PrompterView = ({ 
  text, 
  position, 
  fontSize, 
  textWidth, 
  showFullScreenButton, 
  isScrolling, 
  scrollSpeed, 
  onViewerWidthChange,
  isFlippedHorizontally,
  isFlippedVertically
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  const reportWidth = useCallback(() => {
    if (containerRef.current && onViewerWidthChange) {
      const viewerWidth = containerRef.current.clientWidth;
      onViewerWidthChange(viewerWidth);
    }
  }, [onViewerWidthChange]);

  useEffect(() => {
    reportWidth();
    window.addEventListener('resize', reportWidth);

    return () => window.removeEventListener('resize', reportWidth);
  }, [reportWidth]);

  useEffect(() => {
    if (textRef.current && containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const readingLinePosition = containerHeight * 0.25; // 1/4 down the screen
      textRef.current.style.top = `${readingLinePosition - position}px`;
    }
  }, [position]);

  useEffect(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }

    if (isScrolling) {
      scrollIntervalRef.current = setInterval(() => {
        if (textRef.current && containerRef.current) {
          const containerHeight = containerRef.current.clientHeight;
          const readingLinePosition = containerHeight * 0.25;
          const currentPosition = parseFloat(textRef.current.style.top) || 0;
          const newPosition = currentPosition - scrollSpeed * 0.5;
          textRef.current.style.top = `${newPosition}px`;
        }
      }, 16); // 60 FPS
    }

    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [isScrolling, scrollSpeed]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  const getFlipStyle = () => {
    let transform = '';
    if (isFlippedHorizontally) transform += 'scaleX(-1) ';
    if (isFlippedVertically) transform += 'scaleY(-1)';
    return transform.trim();
  };

  const getIndicatorStyle = () => {
    const style = {
      top: '25%',
      width: 0,
      height: 0,
      borderTop: '20px solid transparent',
      borderBottom: '20px solid transparent',
    };

    if (isFlippedHorizontally) {
      style.right = 0;
      style.borderRight = '40px solid red';
    } else {
      style.left = 0;
      style.borderLeft = '40px solid red';
    }

    if (isFlippedVertically) {
      style.top = '75%';
      style.transform = 'scaleY(-1)';
    }

    return style;
  };

  const getFullScreenButtonStyle = () => {
    const style = {
      position: 'absolute',
      zIndex: 10,
    };

    if (isFlippedHorizontally) {
      style.left = '1rem';
    } else {
      style.right = '1rem';
    }

    if (isFlippedVertically) {
      style.bottom = '1rem';
    } else {
      style.top = '1rem';
    }

    return style;
  };

  const getLogoStyle = () => {
    const style = {
      position: 'absolute',
      zIndex: 10,
      transform: getFlipStyle(),
    };

    if (isFlippedHorizontally) {
      style.left = '1rem';
    } else {
      style.right = '1rem';
    }

    if (isFlippedVertically) {
      style.top = '1rem';
    } else {
      style.bottom = '1rem';
    }

    return style;
  };

  return (
    <div ref={containerRef} className="h-full flex flex-col bg-black text-white overflow-hidden relative">
      {showFullScreenButton && (
        <button
          onClick={toggleFullScreen}
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 flex items-center justify-center w-10 h-10"
          style={getFullScreenButtonStyle()}
        >
          {isFullScreen ? <FaCompress /> : <FaExpand />}
        </button>
      )}
      <div className="flex-grow flex justify-center overflow-hidden relative">
        <div
          className="absolute"
          style={getIndicatorStyle()}
        />
        <div
          className="relative"
          style={{ 
            width: `${textWidth}px`,
            transform: getFlipStyle(),
          }}
        >
          <div
            ref={textRef}
            className="absolute left-0 whitespace-pre-wrap text-left"
            style={{
              fontSize: `${fontSize}px`,
              lineHeight: '1.5',
            }}
          >
            {text}
          </div>
        </div>
      </div>
      {(isFlippedHorizontally || isFlippedVertically) ? (
        <div style={getLogoStyle()}>
          <a href="https://www.nextslide.io" target="_blank" rel="noopener noreferrer">
            <img src="https://www.nextslide.io/wp-content/uploads/2024/06/NS-blue.png" alt="NextSlide Logo" className="w-72 h-auto" />
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default PrompterView;