import React, { useState } from 'react';
import MathCaptcha from './MathCaptcha';
import WordLimitModal from './WordLimitModal';

const WORD_LIMIT = 2500;

const ScriptInput = ({ text, setText, onSubmit }) => {
  const [showLimitModal, setShowLimitModal] = useState(false);
  const wordCount = text.trim() ? text.trim().split(/\s+/).length : 0;
  const readingTimeMinutes = wordCount > 0 ? Math.ceil(wordCount / 150) : 0;

  const handleTextChange = (e) => {
    const newText = e.target.value;
    const newWordCount = newText.trim() ? newText.trim().split(/\s+/).length : 0;
    if (newWordCount <= WORD_LIMIT) {
      setText(newText);
    } else {
      setShowLimitModal(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center p-4">
      <div className="w-full max-w-[1000px] bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-4">NextPrompt</h1>
        <h2 className="text-xl font-bold mb-4">
          FREE remote control teleprompter brought to you by{' '}
          <a
            href="https://www.nextslide.io"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            NextSlide
          </a>
        </h2>
        <div className="mb-4">
          <p className="text-sm text-gray-600">
            Word Count: {wordCount}/{WORD_LIMIT} | Estimated Reading Time:{' '}
            {readingTimeMinutes} minute{readingTimeMinutes !== 1 ? 's' : ''}
          </p>
        </div>
        <textarea
          value={text}
          onChange={handleTextChange}
          className="w-full h-96 bg-white text-gray-800 p-4 mb-4 rounded border"
          placeholder="Paste your script here..."
        />
        <MathCaptcha onSubmit={onSubmit} />
        <WordLimitModal isOpen={showLimitModal} onClose={() => setShowLimitModal(false)} />
        <div className="mt-4 text-center text-gray-600 text-sm">
          <p>
            NextPrompt is powered by{' '}
            <a
              href="https://www.nextslide.io"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              NextSlide
            </a>
            , the best way to control your presentations.
          </p>
          <p>
            Turn your phone into a powerpoint clicker and your tablet or laptop into a confidence
            monitor with{' '}
            <a
              href="https://www.nextslide.io"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              NextSlide
            </a>
          </p>
          <p>© Unmanage, LLC 2024</p>
        </div>
      </div>
    </div>
  );
};

export default ScriptInput;